import React from "react";
import {
  FiFacebook,
  FiGithub,
  FiInstagram,
  FiLinkedin,
  FiTwitter,
} from "react-icons/fi";
import { IoLogoWhatsapp } from "react-icons/io5";

// External
import styled from "styled-components";
import { breakpoints, fontFamily } from "../../../theme";
import SplashText from "../../components/SplashText";
import useMediaQuery from "../../hooks/use-media-query";
import { links } from "../../../config";
import { Element } from "react-scroll";

// Internal

// Styles
const Section = styled(Element)`
  padding: 20px 0px 60px;

  &:first-of-type {
    // padding: 120px 0 70px;
  }
  background-color: ${props => (props.bgColor ? props.bgColor : "#ffffff")};

  @media only screen and (min-width: ${breakpoints.tablet}) {
    padding: 70px 0 150px;

    &:first-of-type {
      padding: 120px 0 70px;
    }
  }
`;

const Heading = styled.h1`
  font-family: ${fontFamily.extraBold};
  font-size: 26px;
  line-height: 120%;
  letter-spacing: -1%;
  text-align: center;
  @media only screen and (min-width: ${breakpoints.tablet}) {
    font-size: 52px;
  }
  @media only screen and (min-width: ${breakpoints.desktop}) {
    font-size: 60px;
  }
`;

const LinkCardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);

  width: 290px;
  margin: 36px auto 20px;

  @media only screen and (min-width: 500px) {
    width: 310px;
  }
  @media only screen and (min-width: ${breakpoints.tablet}) {
    margin: 120px auto 60px;
    width: 520px;
    grid-template-columns: repeat(3, 1fr);
  }

  @media only screen and (min-width: 900px) {
    width: fit-content;
    grid-gap: 10px 40px;
  }

  @media only screen and (min-width: 1200px) {
    grid-gap: 30px 60px;
  }
`;
const LinkCard = styled.a`
  display: flex;
  align-items: center;
  // justify-content:  center;
  border-radius: 24px;
  width: 133px;
  height: 50.78px;
  background: #fff;
  color: #000;
  margin: 12px 0;
  align-self: center;
  justify-self: center;
  text-decoration: none;

  @media only screen and (min-width: ${breakpoints.tablet}) {
    height: 100px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    padding-top: 12px;
    padding-bottom: 24px;
  }
  @media only screen and (min-width: 900px) {
    width: 150px;
    height: 130px;
  }

  @media only screen and (min-width: ${breakpoints.desktop}) {
    width: 170px;
    height: 150px;
  }
  @media only screen and (min-width: 1200px) {
    width: 210px;
    height: 190px;
  }
`;
const LinkCardAvatar = styled.div`
  height: 33px;
  width: 33px;
  border-radius: 50%;
  background-color: ${props => props.bgColor};

  display: flex;
  align-items: center;
  justify-content: center;

  margin-left: 10px;
  margin-right: 8px;

  @media only screen and (min-width: ${breakpoints.desktop}) {
    height: 50px;
    width: 50px;
  }
`;
const LinkCardText = styled.div`
  font-family: ${fontFamily.medium};
  font-size: 14px;
  @media only screen and (min-width: ${breakpoints.tablet}) {
    font-size: 18px;
    font-family: ${fontFamily.extraBold};
    margin-left: 14px;
  }
  @media only screen and (min-width: ${breakpoints.desktop}) {
    font-size: 22px;
  }
  @media only screen and (min-width: 1200px) {
    font-size: 28px;
    font-family: ${fontFamily.bold};
  }
`;

const AroundTheWebSection = () => {
  const isDesktop = useMediaQuery(`(min-width: ${breakpoints.desktop} )`);

  const iconSize = isDesktop ? "28px" : "20px";

  // data
  const socialLinks = [
    {
      name: "twitter",
      label: "Twitter",
      icon: <FiTwitter size={iconSize} color="#534946" />,
      link: links.twitter,
    },

    {
      name: "linkedin",
      label: "Linkedin",
      icon: <FiLinkedin size={iconSize} color="#534946" />,
      link: links.linkedin,
    },

    {
      name: "instagram",
      label: "Instagram",
      icon: <FiInstagram size={iconSize} color="#534946" />,
      link: links.instagram,
    },

    {
      name: "github",
      label: "Github",
      icon: <FiGithub size={iconSize} color="#534946" />,
      link: links.github,
    },
    {
      name: "facebook",
      label: "Facebook",
      icon: <FiFacebook size={iconSize} color="#534946" />,
      link: links.facebook,
    },
    {
      name: "whatsapp",
      label: "Whatsapp",
      icon: <IoLogoWhatsapp size={iconSize} color="#534946" />,
      link: links.whatsapp,
    },
  ];

  return (
    <Section name="contactMe" as="section" bgColor="#f5f5f5">
      <Heading>
        Around the&nbsp;
        <SplashText variant="peach">web</SplashText>
      </Heading>
      <LinkCardGrid>
        {socialLinks.map(socialLinkItem => {
          return (
            <LinkCard
              href={socialLinkItem.link}
              key={socialLinkItem.name}
              target="_blank"
            >
              <LinkCardAvatar bgColor="#FFF3F0">
                {socialLinkItem.icon}
              </LinkCardAvatar>
              <LinkCardText>{socialLinkItem.label}</LinkCardText>
            </LinkCard>
          );
        })}
      </LinkCardGrid>
    </Section>
  );
};

export default AroundTheWebSection;
